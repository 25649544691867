export default
{
  "STATION": [
    {
      "ID": "2199",
      "STID": "RRKN2",
      "NAME": "RED ROCK",
      "ELEVATION": "3756.0",
      "LATITUDE": "36.134720",
      "LONGITUDE": "-115.43",
      "STATUS": "ACTIVE",
      "MNET_ID": "2",
      "STATE": "NV",
      "TIMEZONE": "America/Los_Angeles",
      "ELEV_DEM": "3773.0",
      "PERIOD_OF_RECORD": {
        "start": "2000-03-24T00:00:00Z",
        "end": "2024-09-21T23:34:00Z"
      },
      "UNITS": {
        "position": "ft",
        "elevation": "ft"
      },
      "SENSOR_VARIABLES": {
        "air_temp": {
          "air_temp_set_1": {}
        },
        "relative_humidity": {
          "relative_humidity_set_1": {}
        },
        "wind_speed": {
          "wind_speed_set_1": {}
        },
        "wind_direction": {
          "wind_direction_set_1": {}
        },
        "peak_wind_speed": {
          "peak_wind_speed_set_1": {}
        },
        "wind_gust": {
          "wind_gust_set_1": {}
        },
        "peak_wind_direction": {
          "peak_wind_direction_set_1": {}
        },
        "solar_radiation": {
          "solar_radiation_set_1": {}
        },
        "volt": {
          "volt_set_1": {}
        },
        "precip_accumulated": {
          "precip_accumulated_set_1d": {
            "derived_from": "precip_accum"
          }
        },
        "precip_intervals": {
          "precip_intervals_set_1d": {
            "derived_from": "precip_accum"
          }
        },
        "wind_cardinal_direction": {
          "wind_cardinal_direction_set_1d": {
            "derived_from": [
              "wind_direction_set_1"
            ]
          }
        },
        "dew_point_temperature": {
          "dew_point_temperature_set_1d": {
            "derived_from": [
              "air_temp_set_1",
              "relative_humidity_set_1"
            ]
          }
        },
        "heat_index": {
          "heat_index_set_1d": {
            "derived_from": [
              "air_temp_set_1",
              "relative_humidity_set_1"
            ]
          }
        }
      },
      "OBSERVATIONS": {
        "date_time": [
          "2024-09-02T00:34:00Z",
          "2024-09-02T01:34:00Z",
          "2024-09-02T02:34:00Z",
          "2024-09-02T03:34:00Z",
          "2024-09-02T04:34:00Z",
          "2024-09-02T05:34:00Z",
          "2024-09-02T06:34:00Z",
          "2024-09-02T07:34:00Z",
          "2024-09-02T08:34:00Z",
          "2024-09-02T09:34:00Z",
          "2024-09-02T10:34:00Z",
          "2024-09-02T11:34:00Z",
          "2024-09-02T12:34:00Z",
          "2024-09-02T13:34:00Z",
          "2024-09-02T14:34:00Z",
          "2024-09-02T15:34:00Z",
          "2024-09-02T16:34:00Z",
          "2024-09-02T17:34:00Z",
          "2024-09-02T18:34:00Z",
          "2024-09-02T19:34:00Z",
          "2024-09-02T20:34:00Z",
          "2024-09-02T21:34:00Z",
          "2024-09-02T22:34:00Z",
          "2024-09-02T23:34:00Z",
          "2024-09-03T00:34:00Z",
          "2024-09-03T01:34:00Z",
          "2024-09-03T02:34:00Z",
          "2024-09-03T03:34:00Z",
          "2024-09-03T04:34:00Z",
          "2024-09-03T05:34:00Z",
          "2024-09-03T06:34:00Z",
          "2024-09-03T07:34:00Z",
          "2024-09-03T08:34:00Z",
          "2024-09-03T09:34:00Z",
          "2024-09-03T10:34:00Z",
          "2024-09-03T11:34:00Z",
          "2024-09-03T12:34:00Z",
          "2024-09-03T13:34:00Z",
          "2024-09-03T14:34:00Z",
          "2024-09-03T15:34:00Z",
          "2024-09-03T16:34:00Z",
          "2024-09-03T17:34:00Z",
          "2024-09-03T18:34:00Z",
          "2024-09-03T19:34:00Z",
          "2024-09-03T20:34:00Z",
          "2024-09-03T21:34:00Z",
          "2024-09-03T22:34:00Z",
          "2024-09-03T23:34:00Z",
          "2024-09-04T00:34:00Z",
          "2024-09-04T01:34:00Z",
          "2024-09-04T02:34:00Z",
          "2024-09-04T03:34:00Z",
          "2024-09-04T04:34:00Z",
          "2024-09-04T05:34:00Z",
          "2024-09-04T06:34:00Z",
          "2024-09-04T07:34:00Z",
          "2024-09-04T08:34:00Z",
          "2024-09-04T09:34:00Z",
          "2024-09-04T10:34:00Z",
          "2024-09-04T11:34:00Z",
          "2024-09-04T12:34:00Z",
          "2024-09-04T13:34:00Z",
          "2024-09-04T14:34:00Z",
          "2024-09-04T15:34:00Z",
          "2024-09-04T16:34:00Z",
          "2024-09-04T17:34:00Z",
          "2024-09-04T18:34:00Z",
          "2024-09-04T19:34:00Z",
          "2024-09-04T20:34:00Z",
          "2024-09-04T21:34:00Z",
          "2024-09-04T22:34:00Z",
          "2024-09-04T23:34:00Z",
          "2024-09-05T00:34:00Z",
          "2024-09-05T01:34:00Z",
          "2024-09-05T02:34:00Z",
          "2024-09-05T03:34:00Z",
          "2024-09-05T04:34:00Z",
          "2024-09-05T05:34:00Z",
          "2024-09-05T06:34:00Z",
          "2024-09-05T07:34:00Z",
          "2024-09-05T08:34:00Z",
          "2024-09-05T09:34:00Z",
          "2024-09-05T10:34:00Z",
          "2024-09-05T11:34:00Z",
          "2024-09-05T12:34:00Z",
          "2024-09-05T13:34:00Z",
          "2024-09-05T14:34:00Z",
          "2024-09-05T15:34:00Z",
          "2024-09-05T16:34:00Z",
          "2024-09-05T17:34:00Z",
          "2024-09-05T18:34:00Z",
          "2024-09-05T19:34:00Z",
          "2024-09-05T20:34:00Z",
          "2024-09-05T21:34:00Z",
          "2024-09-05T22:34:00Z",
          "2024-09-05T23:34:00Z",
          "2024-09-06T00:34:00Z",
          "2024-09-06T01:34:00Z",
          "2024-09-06T02:34:00Z",
          "2024-09-06T03:34:00Z",
          "2024-09-06T04:34:00Z",
          "2024-09-06T05:34:00Z",
          "2024-09-06T06:34:00Z",
          "2024-09-06T07:34:00Z",
          "2024-09-06T08:34:00Z",
          "2024-09-06T09:34:00Z",
          "2024-09-06T10:34:00Z",
          "2024-09-06T11:34:00Z",
          "2024-09-06T12:34:00Z",
          "2024-09-06T13:34:00Z",
          "2024-09-06T14:34:00Z",
          "2024-09-06T15:34:00Z",
          "2024-09-06T16:34:00Z",
          "2024-09-06T17:34:00Z",
          "2024-09-06T18:34:00Z",
          "2024-09-06T19:34:00Z",
          "2024-09-06T20:34:00Z",
          "2024-09-06T21:34:00Z",
          "2024-09-06T22:34:00Z",
          "2024-09-06T23:34:00Z",
          "2024-09-07T00:34:00Z",
          "2024-09-07T01:34:00Z",
          "2024-09-07T02:34:00Z",
          "2024-09-07T03:34:00Z",
          "2024-09-07T04:34:00Z",
          "2024-09-07T05:34:00Z",
          "2024-09-07T06:34:00Z",
          "2024-09-07T07:34:00Z",
          "2024-09-07T08:34:00Z",
          "2024-09-07T09:34:00Z",
          "2024-09-07T10:34:00Z",
          "2024-09-07T11:34:00Z",
          "2024-09-07T12:34:00Z",
          "2024-09-07T13:34:00Z",
          "2024-09-07T14:34:00Z",
          "2024-09-07T15:34:00Z",
          "2024-09-07T16:34:00Z",
          "2024-09-07T17:34:00Z",
          "2024-09-07T18:34:00Z",
          "2024-09-07T19:34:00Z",
          "2024-09-07T20:34:00Z",
          "2024-09-07T21:34:00Z",
          "2024-09-07T22:34:00Z",
          "2024-09-07T23:34:00Z",
          "2024-09-08T00:34:00Z",
          "2024-09-08T01:34:00Z",
          "2024-09-08T02:34:00Z",
          "2024-09-08T03:34:00Z",
          "2024-09-08T04:34:00Z",
          "2024-09-08T05:34:00Z",
          "2024-09-08T06:34:00Z",
          "2024-09-08T07:34:00Z",
          "2024-09-08T08:34:00Z",
          "2024-09-08T09:34:00Z",
          "2024-09-08T10:34:00Z",
          "2024-09-08T11:34:00Z",
          "2024-09-08T12:34:00Z",
          "2024-09-08T13:34:00Z",
          "2024-09-08T14:34:00Z",
          "2024-09-08T15:34:00Z",
          "2024-09-08T16:34:00Z",
          "2024-09-08T17:34:00Z",
          "2024-09-08T18:34:00Z",
          "2024-09-08T19:34:00Z",
          "2024-09-08T20:34:00Z",
          "2024-09-08T21:34:00Z",
          "2024-09-08T22:34:00Z",
          "2024-09-08T23:34:00Z",
          "2024-09-09T00:34:00Z",
          "2024-09-09T01:34:00Z",
          "2024-09-09T02:34:00Z",
          "2024-09-09T03:34:00Z",
          "2024-09-09T04:34:00Z",
          "2024-09-09T05:34:00Z",
          "2024-09-09T06:34:00Z",
          "2024-09-09T07:34:00Z",
          "2024-09-09T08:34:00Z",
          "2024-09-09T09:34:00Z",
          "2024-09-09T10:34:00Z",
          "2024-09-09T11:34:00Z",
          "2024-09-09T12:34:00Z",
          "2024-09-09T13:34:00Z",
          "2024-09-09T14:34:00Z",
          "2024-09-09T15:34:00Z",
          "2024-09-09T16:34:00Z",
          "2024-09-09T17:34:00Z",
          "2024-09-09T18:34:00Z",
          "2024-09-09T19:34:00Z",
          "2024-09-09T20:34:00Z",
          "2024-09-09T21:34:00Z",
          "2024-09-09T22:34:00Z",
          "2024-09-09T23:34:00Z",
          "2024-09-10T00:34:00Z",
          "2024-09-10T01:34:00Z",
          "2024-09-10T02:34:00Z",
          "2024-09-10T03:34:00Z",
          "2024-09-10T04:34:00Z",
          "2024-09-10T05:34:00Z",
          "2024-09-10T06:34:00Z",
          "2024-09-10T07:34:00Z",
          "2024-09-10T08:34:00Z",
          "2024-09-10T09:34:00Z",
          "2024-09-10T10:34:00Z",
          "2024-09-10T11:34:00Z",
          "2024-09-10T12:34:00Z",
          "2024-09-10T13:34:00Z",
          "2024-09-10T14:34:00Z",
          "2024-09-10T15:34:00Z",
          "2024-09-10T16:34:00Z",
          "2024-09-10T17:34:00Z",
          "2024-09-10T18:34:00Z",
          "2024-09-10T19:34:00Z",
          "2024-09-10T20:34:00Z",
          "2024-09-10T21:34:00Z",
          "2024-09-10T22:34:00Z",
          "2024-09-10T23:34:00Z",
          "2024-09-11T00:34:00Z",
          "2024-09-11T01:34:00Z",
          "2024-09-11T02:34:00Z",
          "2024-09-11T03:34:00Z",
          "2024-09-11T04:34:00Z",
          "2024-09-11T05:34:00Z",
          "2024-09-11T06:34:00Z",
          "2024-09-11T07:34:00Z",
          "2024-09-11T08:34:00Z",
          "2024-09-11T09:34:00Z",
          "2024-09-11T10:34:00Z",
          "2024-09-11T11:34:00Z",
          "2024-09-11T12:34:00Z",
          "2024-09-11T13:34:00Z",
          "2024-09-11T14:34:00Z",
          "2024-09-11T15:34:00Z",
          "2024-09-11T16:34:00Z",
          "2024-09-11T17:34:00Z",
          "2024-09-11T18:34:00Z",
          "2024-09-11T19:34:00Z",
          "2024-09-11T20:34:00Z",
          "2024-09-11T21:34:00Z",
          "2024-09-11T22:34:00Z",
          "2024-09-11T23:34:00Z",
          "2024-09-12T00:34:00Z",
          "2024-09-12T01:34:00Z",
          "2024-09-12T02:34:00Z",
          "2024-09-12T03:34:00Z",
          "2024-09-12T04:34:00Z",
          "2024-09-12T05:34:00Z",
          "2024-09-12T06:34:00Z",
          "2024-09-12T07:34:00Z",
          "2024-09-12T08:34:00Z",
          "2024-09-12T09:34:00Z",
          "2024-09-12T10:34:00Z",
          "2024-09-12T11:34:00Z",
          "2024-09-12T12:34:00Z",
          "2024-09-12T13:34:00Z",
          "2024-09-12T14:34:00Z",
          "2024-09-12T15:34:00Z",
          "2024-09-12T16:34:00Z",
          "2024-09-12T17:34:00Z",
          "2024-09-12T18:34:00Z",
          "2024-09-12T19:34:00Z",
          "2024-09-12T20:34:00Z",
          "2024-09-12T21:34:00Z",
          "2024-09-12T22:34:00Z",
          "2024-09-12T23:34:00Z",
          "2024-09-13T00:34:00Z",
          "2024-09-13T01:34:00Z",
          "2024-09-13T02:34:00Z",
          "2024-09-13T03:34:00Z",
          "2024-09-13T04:34:00Z",
          "2024-09-13T05:34:00Z",
          "2024-09-13T06:34:00Z",
          "2024-09-13T07:34:00Z",
          "2024-09-13T08:34:00Z",
          "2024-09-13T09:34:00Z",
          "2024-09-13T10:34:00Z",
          "2024-09-13T11:34:00Z",
          "2024-09-13T12:34:00Z",
          "2024-09-13T13:34:00Z",
          "2024-09-13T14:34:00Z",
          "2024-09-13T15:34:00Z",
          "2024-09-13T16:34:00Z",
          "2024-09-13T17:34:00Z",
          "2024-09-13T18:34:00Z",
          "2024-09-13T19:34:00Z",
          "2024-09-13T20:34:00Z",
          "2024-09-13T21:34:00Z",
          "2024-09-13T22:34:00Z",
          "2024-09-13T23:34:00Z",
          "2024-09-14T00:34:00Z",
          "2024-09-14T01:34:00Z",
          "2024-09-14T02:34:00Z",
          "2024-09-14T03:34:00Z",
          "2024-09-14T04:34:00Z",
          "2024-09-14T05:34:00Z",
          "2024-09-14T06:34:00Z",
          "2024-09-14T07:34:00Z",
          "2024-09-14T08:34:00Z",
          "2024-09-14T09:34:00Z",
          "2024-09-14T10:34:00Z",
          "2024-09-14T11:34:00Z",
          "2024-09-14T12:34:00Z",
          "2024-09-14T13:34:00Z",
          "2024-09-14T14:34:00Z",
          "2024-09-14T15:34:00Z",
          "2024-09-14T16:34:00Z",
          "2024-09-14T17:34:00Z",
          "2024-09-14T18:34:00Z",
          "2024-09-14T19:34:00Z",
          "2024-09-14T20:34:00Z",
          "2024-09-14T21:34:00Z",
          "2024-09-14T22:34:00Z",
          "2024-09-14T23:34:00Z",
          "2024-09-15T00:34:00Z",
          "2024-09-15T01:34:00Z",
          "2024-09-15T02:34:00Z",
          "2024-09-15T03:34:00Z",
          "2024-09-15T04:34:00Z",
          "2024-09-15T05:34:00Z",
          "2024-09-15T06:34:00Z",
          "2024-09-15T07:34:00Z",
          "2024-09-15T08:34:00Z",
          "2024-09-15T09:34:00Z",
          "2024-09-15T10:34:00Z",
          "2024-09-15T11:34:00Z",
          "2024-09-15T12:34:00Z",
          "2024-09-15T13:34:00Z",
          "2024-09-15T14:34:00Z",
          "2024-09-15T15:34:00Z",
          "2024-09-15T16:34:00Z",
          "2024-09-15T17:34:00Z",
          "2024-09-15T18:34:00Z",
          "2024-09-15T19:34:00Z",
          "2024-09-15T20:34:00Z",
          "2024-09-15T21:34:00Z",
          "2024-09-15T22:34:00Z",
          "2024-09-15T23:34:00Z",
          "2024-09-16T00:34:00Z",
          "2024-09-16T01:34:00Z",
          "2024-09-16T02:34:00Z",
          "2024-09-16T03:34:00Z",
          "2024-09-16T04:34:00Z",
          "2024-09-16T05:34:00Z",
          "2024-09-16T06:34:00Z",
          "2024-09-16T07:34:00Z",
          "2024-09-16T08:34:00Z",
          "2024-09-16T09:34:00Z",
          "2024-09-16T10:34:00Z",
          "2024-09-16T11:34:00Z",
          "2024-09-16T12:34:00Z",
          "2024-09-16T13:34:00Z",
          "2024-09-16T14:34:00Z",
          "2024-09-16T15:34:00Z",
          "2024-09-16T16:34:00Z",
          "2024-09-16T17:34:00Z",
          "2024-09-16T18:34:00Z",
          "2024-09-16T19:34:00Z",
          "2024-09-16T20:34:00Z",
          "2024-09-16T21:34:00Z",
          "2024-09-16T22:34:00Z",
          "2024-09-16T23:34:00Z",
          "2024-09-17T00:34:00Z",
          "2024-09-17T01:34:00Z",
          "2024-09-17T02:34:00Z",
          "2024-09-17T03:34:00Z",
          "2024-09-17T04:34:00Z",
          "2024-09-17T05:34:00Z",
          "2024-09-17T06:34:00Z",
          "2024-09-17T07:34:00Z",
          "2024-09-17T08:34:00Z",
          "2024-09-17T09:34:00Z",
          "2024-09-17T10:34:00Z",
          "2024-09-17T11:34:00Z",
          "2024-09-17T12:34:00Z",
          "2024-09-17T13:34:00Z",
          "2024-09-17T14:34:00Z",
          "2024-09-17T15:34:00Z",
          "2024-09-17T16:34:00Z",
          "2024-09-17T17:34:00Z",
          "2024-09-17T18:34:00Z",
          "2024-09-17T19:34:00Z",
          "2024-09-17T20:34:00Z",
          "2024-09-17T21:34:00Z",
          "2024-09-17T22:34:00Z",
          "2024-09-17T23:34:00Z",
          "2024-09-18T00:34:00Z",
          "2024-09-18T01:34:00Z",
          "2024-09-18T02:34:00Z",
          "2024-09-18T03:34:00Z",
          "2024-09-18T04:34:00Z",
          "2024-09-18T05:34:00Z",
          "2024-09-18T06:34:00Z",
          "2024-09-18T07:34:00Z",
          "2024-09-18T08:34:00Z",
          "2024-09-18T09:34:00Z",
          "2024-09-18T10:34:00Z",
          "2024-09-18T11:34:00Z",
          "2024-09-18T12:34:00Z",
          "2024-09-18T13:34:00Z",
          "2024-09-18T14:34:00Z",
          "2024-09-18T15:34:00Z",
          "2024-09-18T16:34:00Z",
          "2024-09-18T17:34:00Z",
          "2024-09-18T18:34:00Z",
          "2024-09-18T19:34:00Z",
          "2024-09-18T20:34:00Z",
          "2024-09-18T21:34:00Z",
          "2024-09-18T22:34:00Z",
          "2024-09-18T23:34:00Z",
          "2024-09-19T00:34:00Z",
          "2024-09-19T01:34:00Z",
          "2024-09-19T02:34:00Z",
          "2024-09-19T03:34:00Z",
          "2024-09-19T04:34:00Z",
          "2024-09-19T05:34:00Z",
          "2024-09-19T06:34:00Z",
          "2024-09-19T07:34:00Z",
          "2024-09-19T08:34:00Z",
          "2024-09-19T09:34:00Z",
          "2024-09-19T10:34:00Z",
          "2024-09-19T11:34:00Z",
          "2024-09-19T12:34:00Z",
          "2024-09-19T13:34:00Z",
          "2024-09-19T14:34:00Z",
          "2024-09-19T15:34:00Z",
          "2024-09-19T16:34:00Z",
          "2024-09-19T17:34:00Z",
          "2024-09-19T18:34:00Z",
          "2024-09-19T19:34:00Z",
          "2024-09-19T20:34:00Z",
          "2024-09-19T21:34:00Z",
          "2024-09-19T22:34:00Z",
          "2024-09-19T23:34:00Z",
          "2024-09-20T00:34:00Z",
          "2024-09-20T01:34:00Z",
          "2024-09-20T02:34:00Z",
          "2024-09-20T03:34:00Z",
          "2024-09-20T04:34:00Z",
          "2024-09-20T05:34:00Z",
          "2024-09-20T06:34:00Z",
          "2024-09-20T07:34:00Z",
          "2024-09-20T08:34:00Z",
          "2024-09-20T09:34:00Z",
          "2024-09-20T10:34:00Z",
          "2024-09-20T11:34:00Z",
          "2024-09-20T12:34:00Z",
          "2024-09-20T13:34:00Z",
          "2024-09-20T14:34:00Z",
          "2024-09-20T15:34:00Z",
          "2024-09-20T16:34:00Z",
          "2024-09-20T17:34:00Z",
          "2024-09-20T18:34:00Z",
          "2024-09-20T19:34:00Z",
          "2024-09-20T20:34:00Z",
          "2024-09-20T21:34:00Z",
          "2024-09-20T22:34:00Z",
          "2024-09-20T23:34:00Z",
          "2024-09-21T00:34:00Z",
          "2024-09-21T01:34:00Z",
          "2024-09-21T02:34:00Z",
          "2024-09-21T03:34:00Z",
          "2024-09-21T04:34:00Z",
          "2024-09-21T05:34:00Z",
          "2024-09-21T06:34:00Z",
          "2024-09-21T07:34:00Z",
          "2024-09-21T08:34:00Z",
          "2024-09-21T09:34:00Z",
          "2024-09-21T10:34:00Z",
          "2024-09-21T11:34:00Z",
          "2024-09-21T12:34:00Z",
          "2024-09-21T13:34:00Z",
          "2024-09-21T14:34:00Z",
          "2024-09-21T15:34:00Z",
          "2024-09-21T16:34:00Z",
          "2024-09-21T17:34:00Z",
          "2024-09-21T18:34:00Z",
          "2024-09-21T19:34:00Z",
          "2024-09-21T20:34:00Z",
          "2024-09-21T21:34:00Z",
          "2024-09-21T22:34:00Z",
          "2024-09-21T23:34:00Z"
        ],
        "air_temp_set_1": [
          96,
          92,
          88,
          79,
          77,
          78,
          77,
          76,
          74,
          75,
          78,
          77,
          71,
          70,
          82,
          89,
          90,
          92,
          93,
          92,
          94,
          93,
          92,
          93,
          94,
          92,
          89,
          87,
          86,
          84,
          82,
          83,
          86,
          85,
          85,
          84,
          82,
          80,
          89,
          89,
          92,
          94,
          96,
          97,
          97,
          96,
          98,
          97,
          97,
          93,
          90,
          88,
          87,
          87,
          86,
          86,
          83,
          83,
          84,
          79,
          77,
          75,
          86,
          90,
          91,
          93,
          95,
          98,
          99,
          100,
          100,
          100,
          100,
          96,
          89,
          89,
          89,
          83,
          81,
          77,
          81,
          84,
          77,
          71,
          71,
          69,
          80,
          88,
          90,
          92,
          93,
          95,
          98,
          97,
          98,
          98,
          97,
          94,
          85,
          79,
          78,
          77,
          79,
          74,
          76,
          73,
          71,
          71,
          66,
          69,
          79,
          89,
          91,
          92,
          93,
          95,
          96,
          97,
          98,
          97,
          96,
          92,
          81,
          78,
          76,
          78,
          77,
          76,
          75,
          75,
          74,
          77,
          80,
          76,
          81,
          91,
          91,
          94,
          93,
          94,
          97,
          96,
          96,
          95,
          93,
          91,
          86,
          82,
          83,
          85,
          84,
          84,
          82,
          76,
          77,
          75,
          71,
          75,
          78,
          87,
          88,
          90,
          91,
          94,
          97,
          91,
          83,
          88,
          87,
          88,
          86,
          77,
          77,
          80,
          75,
          72,
          74,
          76,
          80,
          78,
          76,
          73,
          79,
          87,
          89,
          91,
          92,
          93,
          98,
          97,
          99,
          98,
          96,
          91,
          89,
          84,
          80,
          82,
          80,
          82,
          82,
          83,
          84,
          84,
          82,
          81,
          85,
          87,
          90,
          91,
          92,
          92,
          94,
          94,
          95,
          94,
          93,
          89,
          87,
          86,
          85,
          83,
          81,
          80,
          79,
          78,
          77,
          76,
          77,
          75,
          78,
          81,
          84,
          86,
          88,
          89,
          90,
          92,
          91,
          91,
          89,
          86,
          84,
          81,
          79,
          79,
          77,
          74,
          71,
          70,
          64,
          66,
          62,
          61,
          70,
          77,
          79,
          80,
          83,
          86,
          86,
          87,
          86,
          86,
          86,
          82,
          75,
          71,
          76,
          69,
          68,
          64,
          64,
          65,
          65,
          62,
          59,
          59,
          69,
          80,
          80,
          81,
          82,
          85,
          86,
          86,
          89,
          89,
          88,
          84,
          82,
          75,
          71,
          71,
          71,
          70,
          65,
          66,
          66,
          63,
          63,
          63,
          73,
          80,
          82,
          83,
          85,
          87,
          88,
          88,
          89,
          91,
          89,
          85,
          83,
          80,
          79,
          78,
          74,
          73,
          72,
          73,
          73,
          73,
          73,
          68,
          75,
          80,
          82,
          85,
          86,
          87,
          88,
          88,
          88,
          86,
          84,
          81,
          80,
          79,
          77,
          74,
          73,
          71,
          69,
          68,
          68,
          66,
          65,
          64,
          65,
          68,
          69,
          70,
          71,
          72,
          73,
          73,
          72,
          72,
          71,
          68,
          66,
          64,
          63,
          62,
          60,
          60,
          56,
          56,
          53,
          50,
          49,
          49,
          57,
          66,
          67,
          70,
          70,
          72,
          74,
          75,
          75,
          76,
          75,
          72,
          67,
          64,
          61,
          59,
          57,
          57,
          56,
          55,
          54,
          56,
          51,
          54,
          64,
          69,
          71,
          73,
          75,
          77,
          78,
          78,
          79,
          79,
          78,
          74,
          67,
          62,
          59,
          59,
          59,
          61,
          59,
          56,
          53,
          53,
          52,
          55,
          63,
          72,
          72,
          74,
          77,
          79,
          79,
          80,
          79,
          81,
          79,
          76,
          74,
          69,
          65,
          63,
          63,
          60,
          60,
          59,
          59,
          62,
          63,
          61,
          62,
          73,
          74,
          75,
          77,
          77,
          79,
          76,
          76,
          76,
          79,
          73,
          71,
          69,
          70,
          69,
          67,
          66,
          62,
          62,
          59,
          59,
          60,
          56,
          67,
          71,
          73,
          76,
          79,
          81,
          82,
          83,
          85,
          84
        ],
        "relative_humidity_set_1": [
          14,
          15,
          18,
          22,
          24,
          24,
          25,
          26,
          28,
          27,
          24,
          25,
          30,
          30,
          22,
          18,
          18,
          18,
          18,
          19,
          18,
          18,
          22,
          19,
          17,
          18,
          19,
          20,
          20,
          21,
          23,
          23,
          22,
          23,
          22,
          23,
          25,
          26,
          21,
          20,
          19,
          19,
          17,
          14,
          15,
          15,
          14,
          14,
          14,
          15,
          16,
          18,
          18,
          19,
          20,
          20,
          22,
          22,
          22,
          27,
          27,
          29,
          22,
          19,
          17,
          17,
          16,
          14,
          13,
          11,
          11,
          12,
          12,
          14,
          17,
          16,
          16,
          18,
          20,
          22,
          20,
          18,
          22,
          27,
          26,
          28,
          21,
          15,
          13,
          13,
          12,
          11,
          10,
          10,
          10,
          10,
          10,
          11,
          13,
          17,
          17,
          17,
          16,
          17,
          15,
          18,
          18,
          17,
          21,
          20,
          15,
          11,
          11,
          11,
          11,
          11,
          10,
          9,
          8,
          8,
          8,
          9,
          12,
          14,
          15,
          13,
          13,
          14,
          15,
          15,
          15,
          13,
          16,
          19,
          16,
          10,
          9,
          9,
          10,
          10,
          9,
          10,
          11,
          14,
          14,
          15,
          18,
          20,
          21,
          20,
          21,
          22,
          23,
          28,
          27,
          28,
          31,
          32,
          29,
          23,
          24,
          22,
          22,
          20,
          17,
          20,
          35,
          21,
          23,
          22,
          25,
          36,
          37,
          30,
          33,
          35,
          37,
          31,
          28,
          29,
          31,
          34,
          32,
          24,
          22,
          21,
          21,
          20,
          15,
          12,
          12,
          12,
          12,
          14,
          15,
          17,
          19,
          19,
          21,
          18,
          18,
          17,
          17,
          17,
          18,
          18,
          16,
          15,
          14,
          13,
          13,
          13,
          13,
          13,
          12,
          12,
          11,
          12,
          13,
          14,
          15,
          17,
          18,
          18,
          19,
          19,
          20,
          20,
          18,
          19,
          17,
          14,
          9,
          6,
          6,
          4,
          4,
          3,
          4,
          5,
          6,
          7,
          9,
          12,
          12,
          12,
          13,
          14,
          16,
          17,
          18,
          18,
          21,
          21,
          18,
          15,
          13,
          13,
          12,
          10,
          10,
          10,
          10,
          10,
          10,
          12,
          14,
          16,
          14,
          17,
          17,
          19,
          19,
          18,
          18,
          21,
          23,
          23,
          18,
          12,
          12,
          12,
          13,
          12,
          11,
          12,
          10,
          10,
          11,
          13,
          14,
          17,
          21,
          18,
          18,
          19,
          23,
          24,
          26,
          28,
          31,
          32,
          25,
          19,
          18,
          25,
          24,
          22,
          19,
          18,
          16,
          15,
          16,
          19,
          21,
          24,
          28,
          35,
          39,
          41,
          43,
          45,
          45,
          42,
          41,
          48,
          39,
          34,
          24,
          23,
          19,
          16,
          17,
          13,
          12,
          13,
          14,
          16,
          17,
          18,
          19,
          22,
          28,
          31,
          35,
          38,
          36,
          39,
          38,
          36,
          37,
          30,
          30,
          28,
          25,
          26,
          24,
          24,
          24,
          26,
          27,
          29,
          29,
          22,
          26,
          28,
          28,
          27,
          34,
          35,
          38,
          40,
          38,
          38,
          31,
          23,
          23,
          22,
          21,
          21,
          19,
          18,
          18,
          17,
          18,
          19,
          21,
          22,
          26,
          26,
          28,
          29,
          30,
          30,
          31,
          30,
          34,
          31,
          25,
          20,
          19,
          19,
          17,
          15,
          15,
          15,
          14,
          14,
          14,
          16,
          18,
          22,
          22,
          21,
          22,
          23,
          26,
          27,
          29,
          30,
          32,
          29,
          23,
          16,
          16,
          17,
          17,
          18,
          17,
          17,
          15,
          13,
          12,
          13,
          24,
          25,
          30,
          33,
          35,
          37,
          38,
          42,
          42,
          39,
          38,
          68,
          67,
          40,
          34,
          32,
          30,
          29,
          30,
          29,
          30,
          28,
          22,
          24,
          26,
          31,
          29,
          29,
          37,
          43,
          52,
          53,
          58,
          55,
          55,
          59,
          38,
          37,
          34,
          30,
          28,
          25,
          23,
          22,
          20,
          20
        ],
        "wind_speed_set_1": [
          1.74,
          4.34,
          1.74,
          6.08,
          5.21,
          6.08,
          2.61,
          5.21,
          3.48,
          0.87,
          4.34,
          2.61,
          6.08,
          4.34,
          3.48,
          3.48,
          5.21,
          9.56,
          7.82,
          6.08,
          3.48,
          11.3,
          6.95,
          5.21,
          5.21,
          6.08,
          6.08,
          6.08,
          6.08,
          3.48,
          4.34,
          3.48,
          11.3,
          11.3,
          10.43,
          7.82,
          4.34,
          3.48,
          1.74,
          4.34,
          6.08,
          6.95,
          5.21,
          9.56,
          7.82,
          5.21,
          11.3,
          11.3,
          10.43,
          7.82,
          8.69,
          6.95,
          7.82,
          6.08,
          7.82,
          5.21,
          4.34,
          4.34,
          6.08,
          4.34,
          6.95,
          2.61,
          0.87,
          6.08,
          7.82,
          6.08,
          6.08,
          6.08,
          5.21,
          10.43,
          7.82,
          6.95,
          0.87,
          1.74,
          4.34,
          7.82,
          7.82,
          6.95,
          3.48,
          6.08,
          7.82,
          6.08,
          6.08,
          6.08,
          4.34,
          6.95,
          3.48,
          3.48,
          4.34,
          5.21,
          5.21,
          6.08,
          4.34,
          5.21,
          6.95,
          6.08,
          6.08,
          2.61,
          4.34,
          3.48,
          3.48,
          3.48,
          2.61,
          6.95,
          9.56,
          8.69,
          6.95,
          5.21,
          6.95,
          2.61,
          4.34,
          1.74,
          3.48,
          3.48,
          6.08,
          5.21,
          4.34,
          4.34,
          4.34,
          6.08,
          5.21,
          2.61,
          4.34,
          6.95,
          2.61,
          7.82,
          6.08,
          4.34,
          6.08,
          5.21,
          3.48,
          6.95,
          7.82,
          3.48,
          2.61,
          0.87,
          6.08,
          3.48,
          7.82,
          4.34,
          6.08,
          6.08,
          7.82,
          17.38,
          12.17,
          6.08,
          5.21,
          6.08,
          6.95,
          8.69,
          7.82,
          6.95,
          3.48,
          1.74,
          1.74,
          3.48,
          2.61,
          5.21,
          5.21,
          0,
          5.21,
          4.34,
          7.82,
          7.82,
          5.21,
          7.82,
          10.43,
          9.56,
          6.08,
          6.95,
          4.34,
          6.08,
          2.61,
          3.48,
          7.82,
          3.48,
          5.21,
          3.48,
          6.95,
          2.61,
          4.34,
          1.74,
          2.61,
          1.74,
          2.61,
          4.34,
          6.95,
          8.69,
          8.69,
          7.82,
          8.69,
          10.43,
          10.43,
          6.95,
          7.82,
          4.34,
          5.21,
          4.34,
          6.08,
          4.34,
          4.34,
          6.95,
          12.17,
          12.17,
          4.34,
          3.48,
          10.43,
          13.9,
          14.77,
          17.38,
          15.64,
          13.03,
          12.17,
          9.56,
          9.56,
          10.43,
          9.56,
          6.95,
          7.82,
          11.3,
          10.43,
          12.17,
          13.03,
          13.03,
          13.03,
          12.17,
          6.08,
          7.82,
          10.43,
          7.82,
          10.43,
          12.17,
          6.95,
          7.82,
          11.3,
          9.56,
          12.17,
          10.43,
          14.77,
          12.17,
          12.17,
          11.3,
          9.56,
          9.56,
          10.43,
          13.03,
          13.9,
          7.82,
          2.61,
          4.34,
          4.34,
          4.34,
          4.34,
          4.34,
          0,
          2.61,
          5.21,
          8.69,
          5.21,
          4.34,
          6.08,
          5.21,
          6.08,
          5.21,
          5.21,
          3.48,
          4.34,
          7.82,
          7.82,
          4.34,
          6.95,
          4.34,
          6.08,
          6.95,
          3.48,
          6.08,
          7.82,
          5.21,
          2.61,
          1.74,
          6.08,
          7.82,
          6.08,
          4.34,
          7.82,
          7.82,
          6.95,
          7.82,
          7.82,
          6.08,
          3.48,
          3.48,
          6.95,
          5.21,
          6.08,
          6.08,
          6.08,
          6.08,
          5.21,
          3.48,
          4.34,
          2.61,
          0.87,
          3.48,
          5.21,
          7.82,
          10.43,
          9.56,
          7.82,
          8.69,
          6.08,
          6.95,
          10.43,
          8.69,
          9.56,
          6.95,
          5.21,
          4.34,
          2.61,
          4.34,
          0.87,
          3.48,
          6.08,
          3.48,
          3.48,
          0.87,
          0,
          5.21,
          7.82,
          7.82,
          9.56,
          10.43,
          13.03,
          11.3,
          13.9,
          15.64,
          13.03,
          13.03,
          11.3,
          9.56,
          9.56,
          6.08,
          12.17,
          9.56,
          9.56,
          10.43,
          7.82,
          12.17,
          7.82,
          6.08,
          10.43,
          11.3,
          13.03,
          13.03,
          17.38,
          9.56,
          14.77,
          15.64,
          18.25,
          17.38,
          13.9,
          16.51,
          13.9,
          13.03,
          13.03,
          12.17,
          8.69,
          10.43,
          5.21,
          13.9,
          3.48,
          6.95,
          5.21,
          2.61,
          2.61,
          0.87,
          5.21,
          5.21,
          6.95,
          5.21,
          6.95,
          6.95,
          6.08,
          6.95,
          5.21,
          5.21,
          1.74,
          5.21,
          1.74,
          2.61,
          7.82,
          6.08,
          6.95,
          1.74,
          4.34,
          5.21,
          6.08,
          2.61,
          2.61,
          5.21,
          5.21,
          6.08,
          6.08,
          4.34,
          6.08,
          5.21,
          4.34,
          6.08,
          6.95,
          4.34,
          4.34,
          5.21,
          2.61,
          4.34,
          3.48,
          5.21,
          1.74,
          6.08,
          6.08,
          6.95,
          4.34,
          6.95,
          1.74,
          1.74,
          4.34,
          4.34,
          5.21,
          6.95,
          6.95,
          3.48,
          6.08,
          6.08,
          4.34,
          6.08,
          7.82,
          2.61,
          6.08,
          5.21,
          5.21,
          5.21,
          3.48,
          5.21,
          4.34,
          4.34,
          1.74,
          6.95,
          10.43,
          1.74,
          4.34,
          4.34,
          4.34,
          4.34,
          6.95,
          3.48,
          12.17,
          6.08,
          1.74,
          17.38,
          10.43,
          6.08,
          2.61,
          2.61,
          2.61,
          1.74,
          2.61,
          4.34,
          5.21,
          2.61,
          1.74,
          6.95,
          3.48,
          6.95,
          8.69,
          5.21,
          5.21,
          6.95,
          6.08,
          5.21,
          7.82,
          7.82
        ],
        "wind_direction_set_1": [
          5,
          73,
          86,
          284,
          278,
          292,
          261,
          259,
          252,
          285,
          283,
          300,
          287,
          284,
          218,
          95,
          76,
          73,
          95,
          53,
          33,
          207,
          171,
          202,
          176,
          248,
          222,
          236,
          253,
          283,
          295,
          329,
          254,
          272,
          275,
          265,
          349,
          299,
          24,
          86,
          81,
          94,
          93,
          212,
          209,
          208,
          248,
          258,
          263,
          247,
          270,
          257,
          255,
          259,
          272,
          260,
          273,
          288,
          336,
          274,
          296,
          258,
          200,
          69,
          75,
          78,
          93,
          116,
          188,
          232,
          201,
          202,
          279,
          271,
          288,
          319,
          317,
          283,
          215,
          270,
          269,
          80,
          287,
          284,
          274,
          286,
          247,
          121,
          81,
          104,
          70,
          95,
          83,
          73,
          104,
          139,
          116,
          93,
          285,
          281,
          286,
          241,
          186,
          285,
          289,
          280,
          285,
          293,
          289,
          229,
          245,
          193,
          108,
          67,
          62,
          119,
          58,
          118,
          137,
          69,
          144,
          130,
          285,
          286,
          291,
          280,
          294,
          232,
          262,
          249,
          201,
          284,
          303,
          45,
          200,
          261,
          86,
          54,
          95,
          63,
          71,
          70,
          291,
          174,
          170,
          177,
          284,
          285,
          255,
          302,
          307,
          306,
          51,
          346,
          57,
          342,
          297,
          82,
          301,
          null,
          79,
          66,
          81,
          74,
          121,
          240,
          312,
          255,
          296,
          288,
          173,
          287,
          191,
          253,
          288,
          266,
          257,
          323,
          324,
          49,
          85,
          321,
          280,
          76,
          78,
          87,
          73,
          74,
          186,
          244,
          242,
          238,
          230,
          249,
          231,
          233,
          297,
          309,
          329,
          278,
          304,
          270,
          265,
          266,
          233,
          301,
          256,
          259,
          245,
          253,
          249,
          244,
          228,
          191,
          238,
          224,
          250,
          217,
          226,
          219,
          208,
          244,
          264,
          266,
          261,
          247,
          221,
          232,
          219,
          182,
          189,
          188,
          208,
          250,
          246,
          229,
          228,
          230,
          233,
          245,
          251,
          231,
          218,
          220,
          259,
          272,
          267,
          299,
          16,
          330,
          275,
          308,
          269,
          284,
          null,
          77,
          121,
          104,
          106,
          122,
          110,
          102,
          140,
          94,
          110,
          95,
          291,
          302,
          318,
          241,
          285,
          273,
          275,
          280,
          302,
          288,
          281,
          258,
          248,
          100,
          106,
          104,
          98,
          78,
          107,
          68,
          157,
          138,
          88,
          70,
          53,
          267,
          290,
          292,
          301,
          299,
          283,
          248,
          257,
          295,
          275,
          300,
          236,
          52,
          54,
          97,
          94,
          101,
          96,
          108,
          89,
          138,
          188,
          212,
          230,
          261,
          255,
          221,
          314,
          295,
          307,
          283,
          274,
          304,
          323,
          308,
          null,
          102,
          116,
          137,
          206,
          224,
          239,
          221,
          221,
          220,
          228,
          221,
          227,
          223,
          238,
          224,
          237,
          229,
          202,
          238,
          228,
          249,
          251,
          252,
          265,
          235,
          246,
          238,
          191,
          220,
          196,
          226,
          201,
          205,
          243,
          239,
          254,
          275,
          265,
          247,
          268,
          263,
          236,
          250,
          264,
          288,
          279,
          312,
          229,
          195,
          111,
          101,
          85,
          80,
          101,
          105,
          73,
          111,
          105,
          107,
          300,
          284,
          248,
          265,
          282,
          271,
          277,
          284,
          237,
          276,
          278,
          261,
          244,
          61,
          96,
          80,
          44,
          121,
          115,
          73,
          133,
          106,
          116,
          110,
          282,
          287,
          297,
          294,
          277,
          293,
          289,
          280,
          287,
          282,
          272,
          282,
          254,
          33,
          85,
          78,
          78,
          81,
          115,
          24,
          60,
          103,
          107,
          100,
          124,
          287,
          286,
          286,
          296,
          290,
          259,
          282,
          252,
          209,
          326,
          48,
          307,
          331,
          101,
          74,
          67,
          96,
          135,
          126,
          253,
          241,
          208,
          333,
          311,
          288,
          215,
          87,
          338,
          339,
          257,
          32,
          289,
          274,
          307,
          266,
          223,
          74,
          82,
          93,
          93,
          105,
          123,
          101,
          249,
          267
        ],
        "peak_wind_speed_set_1": [
          6.95,
          7.82,
          5.21,
          6.95,
          7.82,
          8.69,
          5.21,
          7.82,
          6.95,
          8.69,
          11.3,
          6.95,
          7.82,
          6.95,
          6.95,
          5.21,
          8.69,
          13.03,
          13.9,
          13.9,
          11.3,
          17.38,
          13.9,
          13.03,
          14.77,
          11.3,
          14.77,
          12.17,
          11.3,
          10.43,
          6.95,
          6.95,
          18.25,
          19.99,
          17.38,
          13.9,
          10.43,
          7.82,
          7.82,
          6.95,
          9.56,
          13.9,
          14.77,
          17.38,
          20.86,
          19.12,
          16.51,
          18.25,
          15.64,
          17.38,
          13.9,
          12.17,
          13.03,
          13.9,
          15.64,
          11.3,
          7.82,
          14.77,
          10.43,
          9.56,
          7.82,
          9.56,
          6.08,
          8.69,
          11.3,
          11.3,
          12.17,
          13.9,
          15.64,
          24.33,
          16.51,
          15.64,
          8.69,
          5.21,
          6.08,
          11.3,
          11.3,
          9.56,
          8.69,
          7.82,
          10.43,
          12.17,
          8.69,
          8.69,
          9.56,
          8.69,
          10.43,
          6.08,
          9.56,
          10.43,
          12.17,
          12.17,
          12.17,
          12.17,
          13.03,
          13.9,
          11.3,
          8.69,
          6.08,
          7.82,
          6.95,
          6.08,
          7.82,
          9.56,
          11.3,
          11.3,
          11.3,
          8.69,
          9.56,
          9.56,
          8.69,
          4.34,
          9.56,
          8.69,
          11.3,
          12.17,
          11.3,
          13.03,
          10.43,
          11.3,
          11.3,
          8.69,
          6.95,
          8.69,
          6.95,
          9.56,
          7.82,
          10.43,
          9.56,
          9.56,
          10.43,
          9.56,
          10.43,
          7.82,
          5.21,
          6.08,
          10.43,
          12.17,
          13.9,
          10.43,
          11.3,
          13.9,
          15.64,
          24.33,
          23.46,
          14.77,
          10.43,
          7.82,
          14.77,
          13.03,
          12.17,
          11.3,
          9.56,
          6.08,
          6.08,
          6.08,
          7.82,
          7.82,
          6.95,
          5.21,
          7.82,
          8.69,
          11.3,
          12.17,
          18.25,
          16.51,
          20.86,
          18.25,
          15.64,
          10.43,
          9.56,
          7.82,
          6.95,
          11.3,
          9.56,
          6.08,
          7.82,
          7.82,
          9.56,
          6.95,
          6.95,
          6.95,
          5.21,
          5.21,
          5.21,
          11.3,
          13.9,
          15.64,
          19.12,
          18.25,
          18.25,
          19.99,
          19.12,
          19.12,
          13.9,
          9.56,
          6.95,
          7.82,
          7.82,
          10.43,
          12.17,
          13.9,
          17.38,
          19.99,
          18.25,
          9.56,
          17.38,
          21.72,
          22.59,
          26.07,
          26.94,
          26.94,
          23.46,
          21.72,
          21.72,
          26.94,
          22.59,
          19.99,
          20.86,
          22.59,
          33.89,
          22.59,
          21.72,
          22.59,
          21.72,
          20.86,
          17.38,
          13.03,
          15.64,
          14.77,
          16.51,
          17.38,
          19.99,
          14.77,
          24.33,
          18.25,
          23.46,
          23.46,
          23.46,
          26.94,
          27.81,
          24.33,
          22.59,
          18.25,
          21.72,
          21.72,
          23.46,
          20.86,
          9.56,
          6.95,
          8.69,
          9.56,
          8.69,
          7.82,
          6.08,
          6.95,
          10.43,
          13.03,
          11.3,
          9.56,
          12.17,
          13.9,
          13.9,
          13.03,
          13.03,
          10.43,
          5.21,
          9.56,
          11.3,
          11.3,
          8.69,
          8.69,
          7.82,
          9.56,
          8.69,
          8.69,
          9.56,
          8.69,
          6.08,
          3.48,
          9.56,
          12.17,
          12.17,
          11.3,
          12.17,
          13.9,
          14.77,
          13.9,
          14.77,
          11.3,
          8.69,
          7.82,
          10.43,
          9.56,
          8.69,
          9.56,
          8.69,
          8.69,
          9.56,
          7.82,
          6.95,
          6.95,
          5.21,
          6.95,
          9.56,
          13.03,
          18.25,
          16.51,
          17.38,
          19.12,
          19.12,
          15.64,
          18.25,
          17.38,
          16.51,
          13.03,
          11.3,
          11.3,
          9.56,
          6.08,
          8.69,
          7.82,
          8.69,
          7.82,
          6.95,
          6.95,
          5.21,
          8.69,
          11.3,
          13.03,
          22.59,
          19.12,
          19.99,
          25.2,
          26.94,
          26.94,
          27.81,
          25.2,
          22.59,
          17.38,
          17.38,
          16.51,
          23.46,
          19.12,
          14.77,
          16.51,
          16.51,
          26.07,
          18.25,
          16.51,
          20.86,
          20.86,
          24.33,
          25.2,
          29.55,
          26.94,
          36.5,
          41.71,
          34.76,
          33.02,
          36.5,
          27.81,
          22.59,
          22.59,
          23.46,
          20.86,
          27.81,
          20.86,
          19.99,
          24.33,
          15.64,
          7.82,
          6.95,
          7.82,
          4.34,
          3.48,
          7.82,
          9.56,
          12.17,
          13.03,
          13.03,
          11.3,
          13.03,
          13.03,
          12.17,
          8.69,
          5.21,
          8.69,
          7.82,
          6.95,
          8.69,
          8.69,
          9.56,
          9.56,
          7.82,
          8.69,
          7.82,
          8.69,
          6.95,
          6.95,
          8.69,
          9.56,
          10.43,
          13.03,
          13.9,
          13.9,
          16.51,
          14.77,
          13.9,
          10.43,
          6.08,
          6.95,
          8.69,
          6.95,
          7.82,
          8.69,
          6.08,
          7.82,
          7.82,
          7.82,
          8.69,
          7.82,
          7.82,
          5.21,
          7.82,
          8.69,
          11.3,
          14.77,
          14.77,
          13.9,
          14.77,
          13.03,
          15.64,
          10.43,
          10.43,
          9.56,
          7.82,
          10.43,
          7.82,
          6.95,
          8.69,
          6.95,
          6.95,
          7.82,
          6.08,
          16.51,
          17.38,
          13.9,
          9.56,
          8.69,
          9.56,
          17.38,
          11.3,
          10.43,
          19.99,
          16.51,
          6.95,
          31.28,
          25.2,
          17.38,
          19.99,
          6.08,
          12.17,
          6.95,
          6.08,
          6.08,
          8.69,
          7.82,
          7.82,
          7.82,
          7.82,
          12.17,
          13.03,
          13.9,
          10.43,
          13.9,
          13.9,
          15.64,
          13.03,
          13.03
        ],
        "wind_gust_set_1": [
          6.95,
          7.82,
          5.21,
          6.95,
          7.82,
          8.69,
          5.21,
          7.82,
          6.95,
          8.69,
          11.3,
          6.95,
          7.82,
          6.95,
          6.95,
          5.21,
          8.69,
          13.03,
          13.9,
          13.9,
          11.3,
          17.38,
          13.9,
          13.03,
          14.77,
          11.3,
          14.77,
          12.17,
          11.3,
          10.43,
          6.95,
          6.95,
          18.25,
          19.99,
          17.38,
          13.9,
          10.43,
          7.82,
          7.82,
          6.95,
          9.56,
          13.9,
          14.77,
          17.38,
          20.86,
          19.12,
          16.51,
          18.25,
          15.64,
          17.38,
          13.9,
          12.17,
          13.03,
          13.9,
          15.64,
          11.3,
          7.82,
          14.77,
          10.43,
          9.56,
          7.82,
          9.56,
          6.08,
          8.69,
          11.3,
          11.3,
          12.17,
          13.9,
          15.64,
          24.33,
          16.51,
          15.64,
          8.69,
          5.21,
          6.08,
          11.3,
          11.3,
          9.56,
          8.69,
          7.82,
          10.43,
          12.17,
          8.69,
          8.69,
          9.56,
          8.69,
          10.43,
          6.08,
          9.56,
          10.43,
          12.17,
          12.17,
          12.17,
          12.17,
          13.03,
          13.9,
          11.3,
          8.69,
          6.08,
          7.82,
          6.95,
          6.08,
          7.82,
          9.56,
          11.3,
          11.3,
          11.3,
          8.69,
          9.56,
          9.56,
          8.69,
          4.34,
          9.56,
          8.69,
          11.3,
          12.17,
          11.3,
          13.03,
          10.43,
          11.3,
          11.3,
          8.69,
          6.95,
          8.69,
          6.95,
          9.56,
          7.82,
          10.43,
          9.56,
          9.56,
          10.43,
          9.56,
          10.43,
          7.82,
          5.21,
          6.08,
          10.43,
          12.17,
          13.9,
          10.43,
          11.3,
          13.9,
          15.64,
          24.33,
          23.46,
          14.77,
          10.43,
          7.82,
          14.77,
          13.03,
          12.17,
          11.3,
          9.56,
          6.08,
          6.08,
          6.08,
          7.82,
          7.82,
          6.95,
          5.21,
          7.82,
          8.69,
          11.3,
          12.17,
          18.25,
          16.51,
          20.86,
          18.25,
          15.64,
          10.43,
          9.56,
          7.82,
          6.95,
          11.3,
          9.56,
          6.08,
          7.82,
          7.82,
          9.56,
          6.95,
          6.95,
          6.95,
          5.21,
          5.21,
          5.21,
          11.3,
          13.9,
          15.64,
          19.12,
          18.25,
          18.25,
          19.99,
          19.12,
          19.12,
          13.9,
          9.56,
          6.95,
          7.82,
          7.82,
          10.43,
          12.17,
          13.9,
          17.38,
          19.99,
          18.25,
          9.56,
          17.38,
          21.72,
          22.59,
          26.07,
          26.94,
          26.94,
          23.46,
          21.72,
          21.72,
          26.94,
          22.59,
          19.99,
          20.86,
          22.59,
          33.89,
          22.59,
          21.72,
          22.59,
          21.72,
          20.86,
          17.38,
          13.03,
          15.64,
          14.77,
          16.51,
          17.38,
          19.99,
          14.77,
          24.33,
          18.25,
          23.46,
          23.46,
          23.46,
          26.94,
          27.81,
          24.33,
          22.59,
          18.25,
          21.72,
          21.72,
          23.46,
          20.86,
          9.56,
          6.95,
          8.69,
          9.56,
          8.69,
          7.82,
          6.08,
          6.95,
          10.43,
          13.03,
          11.3,
          9.56,
          12.17,
          13.9,
          13.9,
          13.03,
          13.03,
          10.43,
          5.21,
          9.56,
          11.3,
          11.3,
          8.69,
          8.69,
          7.82,
          9.56,
          8.69,
          8.69,
          9.56,
          8.69,
          6.08,
          3.48,
          9.56,
          12.17,
          12.17,
          11.3,
          12.17,
          13.9,
          14.77,
          13.9,
          14.77,
          11.3,
          8.69,
          7.82,
          10.43,
          9.56,
          8.69,
          9.56,
          8.69,
          8.69,
          9.56,
          7.82,
          6.95,
          6.95,
          5.21,
          6.95,
          9.56,
          13.03,
          18.25,
          16.51,
          17.38,
          19.12,
          19.12,
          15.64,
          18.25,
          17.38,
          16.51,
          13.03,
          11.3,
          11.3,
          9.56,
          6.08,
          8.69,
          7.82,
          8.69,
          7.82,
          6.95,
          6.95,
          5.21,
          8.69,
          11.3,
          13.03,
          22.59,
          19.12,
          19.99,
          25.2,
          26.94,
          26.94,
          27.81,
          25.2,
          22.59,
          17.38,
          17.38,
          16.51,
          23.46,
          19.12,
          14.77,
          16.51,
          16.51,
          26.07,
          18.25,
          16.51,
          20.86,
          20.86,
          24.33,
          25.2,
          29.55,
          26.94,
          36.5,
          41.71,
          34.76,
          33.02,
          36.5,
          27.81,
          22.59,
          22.59,
          23.46,
          20.86,
          27.81,
          20.86,
          19.99,
          24.33,
          15.64,
          7.82,
          6.95,
          7.82,
          4.34,
          3.48,
          7.82,
          9.56,
          12.17,
          13.03,
          13.03,
          11.3,
          13.03,
          13.03,
          12.17,
          8.69,
          5.21,
          8.69,
          7.82,
          6.95,
          8.69,
          8.69,
          9.56,
          9.56,
          7.82,
          8.69,
          7.82,
          8.69,
          6.95,
          6.95,
          8.69,
          9.56,
          10.43,
          13.03,
          13.9,
          13.9,
          16.51,
          14.77,
          13.9,
          10.43,
          6.08,
          6.95,
          8.69,
          6.95,
          7.82,
          8.69,
          6.08,
          7.82,
          7.82,
          7.82,
          8.69,
          7.82,
          7.82,
          5.21,
          7.82,
          8.69,
          11.3,
          14.77,
          14.77,
          13.9,
          14.77,
          13.03,
          15.64,
          10.43,
          10.43,
          9.56,
          7.82,
          10.43,
          7.82,
          6.95,
          8.69,
          6.95,
          6.95,
          7.82,
          6.08,
          16.51,
          17.38,
          13.9,
          9.56,
          8.69,
          9.56,
          17.38,
          11.3,
          10.43,
          19.99,
          16.51,
          6.95,
          31.28,
          25.2,
          17.38,
          19.99,
          6.08,
          12.17,
          6.95,
          6.08,
          6.08,
          8.69,
          7.82,
          7.82,
          7.82,
          7.82,
          12.17,
          13.03,
          13.9,
          10.43,
          13.9,
          13.9,
          15.64,
          13.03,
          13.03
        ],
        "peak_wind_direction_set_1": [
          104,
          82,
          71,
          283,
          279,
          281,
          283,
          268,
          285,
          297,
          224,
          321,
          283,
          283,
          279,
          83,
          94,
          82,
          63,
          67,
          300,
          196,
          181,
          170,
          163,
          246,
          191,
          218,
          236,
          242,
          278,
          295,
          257,
          268,
          268,
          257,
          269,
          328,
          308,
          94,
          55,
          96,
          94,
          125,
          201,
          230,
          239,
          267,
          258,
          248,
          226,
          258,
          256,
          236,
          263,
          263,
          262,
          258,
          264,
          318,
          304,
          316,
          226,
          92,
          85,
          88,
          79,
          116,
          187,
          223,
          196,
          212,
          212,
          334,
          244,
          301,
          320,
          318,
          283,
          274,
          269,
          98,
          76,
          279,
          283,
          282,
          288,
          101,
          119,
          118,
          96,
          87,
          86,
          100,
          79,
          102,
          118,
          97,
          290,
          281,
          282,
          240,
          275,
          282,
          288,
          285,
          292,
          297,
          282,
          281,
          255,
          270,
          117,
          89,
          100,
          93,
          124,
          129,
          108,
          62,
          103,
          89,
          278,
          280,
          282,
          314,
          273,
          285,
          288,
          275,
          282,
          270,
          307,
          295,
          60,
          249,
          85,
          66,
          84,
          94,
          69,
          70,
          317,
          180,
          194,
          157,
          181,
          283,
          184,
          301,
          306,
          325,
          311,
          322,
          73,
          290,
          279,
          78,
          305,
          308,
          77,
          84,
          86,
          89,
          89,
          118,
          311,
          223,
          279,
          301,
          170,
          283,
          279,
          113,
          285,
          292,
          277,
          286,
          327,
          323,
          84,
          91,
          306,
          95,
          68,
          86,
          80,
          57,
          164,
          236,
          216,
          243,
          215,
          227,
          211,
          239,
          307,
          279,
          337,
          253,
          275,
          260,
          260,
          272,
          272,
          339,
          258,
          249,
          248,
          249,
          252,
          250,
          261,
          214,
          208,
          201,
          244,
          218,
          255,
          213,
          209,
          216,
          247,
          290,
          270,
          244,
          258,
          220,
          267,
          175,
          195,
          184,
          197,
          254,
          239,
          203,
          224,
          268,
          232,
          227,
          217,
          229,
          238,
          197,
          218,
          251,
          267,
          256,
          275,
          236,
          309,
          279,
          279,
          287,
          287,
          65,
          102,
          76,
          118,
          118,
          102,
          120,
          97,
          111,
          102,
          58,
          94,
          295,
          319,
          304,
          287,
          283,
          282,
          282,
          281,
          293,
          283,
          283,
          271,
          196,
          104,
          90,
          96,
          111,
          119,
          94,
          81,
          96,
          111,
          96,
          60,
          52,
          282,
          301,
          306,
          292,
          280,
          267,
          287,
          269,
          285,
          203,
          278,
          72,
          70,
          90,
          110,
          96,
          118,
          107,
          89,
          98,
          194,
          172,
          224,
          235,
          257,
          253,
          218,
          294,
          243,
          243,
          269,
          268,
          305,
          281,
          278,
          112,
          129,
          150,
          139,
          213,
          240,
          214,
          284,
          229,
          234,
          209,
          222,
          217,
          195,
          282,
          230,
          216,
          244,
          245,
          227,
          236,
          245,
          235,
          253,
          253,
          213,
          256,
          195,
          191,
          208,
          200,
          184,
          185,
          202,
          234,
          248,
          239,
          282,
          249,
          237,
          292,
          276,
          263,
          255,
          282,
          271,
          282,
          238,
          214,
          121,
          104,
          97,
          97,
          98,
          117,
          95,
          100,
          125,
          139,
          115,
          302,
          280,
          283,
          280,
          281,
          279,
          285,
          279,
          280,
          289,
          283,
          273,
          81,
          85,
          86,
          65,
          86,
          99,
          115,
          108,
          121,
          99,
          118,
          269,
          283,
          282,
          283,
          281,
          284,
          282,
          277,
          281,
          278,
          284,
          281,
          279,
          13,
          68,
          77,
          83,
          138,
          147,
          101,
          102,
          58,
          134,
          48,
          136,
          143,
          285,
          267,
          282,
          276,
          301,
          281,
          284,
          194,
          223,
          120,
          304,
          310,
          101,
          93,
          97,
          122,
          130,
          120,
          258,
          255,
          234,
          318,
          323,
          309,
          303,
          190,
          115,
          314,
          275,
          276,
          306,
          288,
          291,
          275,
          245,
          80,
          76,
          85,
          93,
          74,
          144,
          103,
          260,
          254
        ],
        "solar_radiation_set_1": [
          381,
          151,
          8,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          91,
          274,
          470,
          645,
          796,
          612,
          254,
          278,
          175,
          156,
          169,
          129,
          13,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          88,
          272,
          470,
          645,
          687,
          884,
          802,
          749,
          507,
          477,
          317,
          162,
          7,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          88,
          272,
          470,
          646,
          785,
          885,
          906,
          834,
          725,
          577,
          341,
          129,
          8,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          82,
          264,
          464,
          642,
          779,
          858,
          871,
          815,
          699,
          535,
          344,
          133,
          7,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          83,
          264,
          467,
          645,
          781,
          857,
          867,
          814,
          697,
          531,
          336,
          126,
          7,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3,
          97,
          300,
          511,
          715,
          671,
          478,
          806,
          629,
          414,
          296,
          334,
          121,
          7,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          61,
          251,
          445,
          620,
          668,
          870,
          907,
          210,
          64,
          44,
          59,
          141,
          23,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          73,
          248,
          442,
          618,
          765,
          809,
          873,
          596,
          663,
          579,
          358,
          120,
          5,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          78,
          232,
          415,
          603,
          731,
          747,
          794,
          794,
          686,
          525,
          340,
          117,
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          74,
          263,
          464,
          623,
          770,
          862,
          873,
          822,
          714,
          547,
          355,
          117,
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          76,
          260,
          459,
          635,
          764,
          841,
          851,
          794,
          675,
          516,
          317,
          98,
          5,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          71,
          251,
          452,
          630,
          764,
          840,
          854,
          797,
          685,
          522,
          323,
          89,
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2,
          72,
          250,
          444,
          613,
          742,
          825,
          840,
          788,
          677,
          476,
          322,
          74,
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          60,
          232,
          431,
          614,
          750,
          828,
          838,
          787,
          677,
          521,
          319,
          63,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          68,
          248,
          448,
          622,
          755,
          827,
          843,
          793,
          676,
          512,
          310,
          53,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          68,
          248,
          449,
          623,
          753,
          830,
          839,
          784,
          670,
          508,
          298,
          50,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          69,
          247,
          448,
          622,
          748,
          824,
          831,
          775,
          664,
          503,
          309,
          45,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          63,
          240,
          347,
          487,
          730,
          892,
          821,
          702,
          662,
          523,
          279,
          59,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          66,
          246,
          395,
          474,
          646,
          734,
          594,
          379,
          447,
          234,
          267,
          36,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          56,
          237,
          429,
          601,
          730,
          806,
          823,
          748,
          659,
          490
        ],
        "volt_set_1": [
          13.4,
          13.3,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.9,
          13.8,
          13.3,
          13.2,
          13.2,
          13.5,
          13.2,
          13.2,
          13.2,
          13.2,
          13.1,
          13.5,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.9,
          13.6,
          13.2,
          13.3,
          13.1,
          13.3,
          13.5,
          13.2,
          13.1,
          13.4,
          13.1,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.9,
          12.9,
          12.8,
          12.9,
          13.7,
          13.2,
          13.2,
          13.3,
          13.2,
          13.1,
          13.2,
          13.1,
          13.2,
          13.3,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          13,
          13.9,
          13.4,
          13.2,
          13.2,
          13.1,
          13.1,
          13.3,
          13.3,
          13.1,
          13.1,
          13.1,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.9,
          12.8,
          12.8,
          12.8,
          13,
          13.9,
          13.2,
          13.2,
          13.2,
          13.4,
          13.2,
          13.3,
          13.1,
          13.1,
          13.3,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.4,
          13.2,
          13.3,
          13.3,
          13.5,
          13.2,
          13.4,
          13.1,
          13.2,
          13.4,
          13.3,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.9,
          12.8,
          12.9,
          13.7,
          13.2,
          13.2,
          13.3,
          13.2,
          13.2,
          13.6,
          13.2,
          13.1,
          13.2,
          13.4,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.7,
          13.3,
          13.2,
          13.2,
          13.1,
          13.2,
          13.3,
          13.4,
          13.1,
          13.1,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.5,
          13.2,
          13.4,
          13.7,
          13.3,
          13.3,
          13.1,
          13.2,
          13.1,
          13.4,
          13,
          12.9,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.9,
          12.8,
          13,
          13.8,
          13.3,
          13.2,
          13.3,
          13.2,
          13.8,
          13.3,
          13.3,
          13.5,
          13.3,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.9,
          12.8,
          13,
          13.9,
          13.3,
          13.4,
          13.6,
          13.3,
          13.3,
          13.2,
          13.7,
          13.7,
          13.2,
          13.3,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          13.9,
          13.4,
          13.3,
          13.3,
          13.5,
          13.3,
          13.2,
          13.2,
          13.6,
          13.7,
          13.1,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.9,
          13.3,
          13.3,
          13.4,
          13.2,
          13.3,
          13.7,
          13.9,
          13.2,
          13.4,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          13.8,
          13.3,
          13.3,
          13.2,
          13.2,
          13.4,
          13.2,
          13.1,
          13.2,
          13.7,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          13.9,
          13.5,
          13.4,
          13.3,
          13.4,
          13.4,
          13.8,
          13.6,
          13.5,
          13.5,
          13.1,
          13,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.7,
          12.7,
          12.7,
          12.7,
          12.9,
          14,
          13.6,
          13.6,
          13.6,
          13.6,
          13.9,
          13.5,
          13.5,
          13.4,
          13.5,
          13.1,
          13,
          13,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          14.1,
          13.4,
          14,
          13.4,
          13.4,
          13.5,
          13.4,
          13.7,
          13.4,
          14,
          13,
          13,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.6,
          13.5,
          13.7,
          13.5,
          13.6,
          13.9,
          13.5,
          13.7,
          13.5,
          13.5,
          13.1,
          13,
          12.9,
          12.9,
          12.9,
          12.9,
          12.8,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          13,
          13.6,
          13.5,
          13.5,
          13.9,
          13.4,
          13.8,
          13.6,
          13.6,
          13.5,
          13.8,
          13,
          13,
          13,
          12.9,
          12.9,
          12.9,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.8,
          12.9,
          14,
          14,
          13.4,
          13.7,
          13.3,
          13.5,
          13.4,
          13.6,
          13.3
        ],
        "precip_accumulated_set_1d": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.01,
          0.04,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17,
          0.17
        ],
        "precip_intervals_set_1d": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0.01,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0.03,
          0.13,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ],
        "wind_cardinal_direction_set_1d": [
          "N",
          "ENE",
          "E",
          "WNW",
          "W",
          "WNW",
          "W",
          "W",
          "WSW",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "SW",
          "E",
          "ENE",
          "ENE",
          "E",
          "NE",
          "NNE",
          "SSW",
          "S",
          "SSW",
          "S",
          "WSW",
          "SW",
          "SW",
          "WSW",
          "WNW",
          "WNW",
          "NNW",
          "WSW",
          "W",
          "W",
          "W",
          "N",
          "WNW",
          "NNE",
          "E",
          "E",
          "E",
          "E",
          "SSW",
          "SSW",
          "SSW",
          "WSW",
          "WSW",
          "W",
          "WSW",
          "W",
          "WSW",
          "WSW",
          "W",
          "W",
          "W",
          "W",
          "WNW",
          "NNW",
          "W",
          "WNW",
          "WSW",
          "SSW",
          "ENE",
          "ENE",
          "ENE",
          "E",
          "ESE",
          "S",
          "SW",
          "SSW",
          "SSW",
          "W",
          "W",
          "WNW",
          "NW",
          "NW",
          "WNW",
          "SW",
          "W",
          "W",
          "E",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WSW",
          "ESE",
          "E",
          "ESE",
          "ENE",
          "E",
          "E",
          "ENE",
          "ESE",
          "SE",
          "ESE",
          "E",
          "WNW",
          "W",
          "WNW",
          "WSW",
          "S",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WNW",
          "WNW",
          "SW",
          "WSW",
          "SSW",
          "ESE",
          "ENE",
          "ENE",
          "ESE",
          "ENE",
          "ESE",
          "SE",
          "ENE",
          "SE",
          "SE",
          "WNW",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "SW",
          "W",
          "WSW",
          "SSW",
          "WNW",
          "WNW",
          "NE",
          "SSW",
          "W",
          "E",
          "NE",
          "E",
          "ENE",
          "ENE",
          "ENE",
          "WNW",
          "S",
          "S",
          "S",
          "WNW",
          "WNW",
          "WSW",
          "WNW",
          "NW",
          "NW",
          "NE",
          "NNW",
          "ENE",
          "NNW",
          "WNW",
          "E",
          "WNW",
          null,
          "E",
          "ENE",
          "E",
          "ENE",
          "ESE",
          "WSW",
          "NW",
          "WSW",
          "WNW",
          "WNW",
          "S",
          "WNW",
          "S",
          "WSW",
          "WNW",
          "W",
          "WSW",
          "NW",
          "NW",
          "NE",
          "E",
          "NW",
          "W",
          "ENE",
          "ENE",
          "E",
          "ENE",
          "ENE",
          "S",
          "WSW",
          "WSW",
          "WSW",
          "SW",
          "WSW",
          "SW",
          "SW",
          "WNW",
          "NW",
          "NNW",
          "W",
          "NW",
          "W",
          "W",
          "W",
          "SW",
          "WNW",
          "WSW",
          "W",
          "WSW",
          "WSW",
          "WSW",
          "WSW",
          "SW",
          "S",
          "WSW",
          "SW",
          "WSW",
          "SW",
          "SW",
          "SW",
          "SSW",
          "WSW",
          "W",
          "W",
          "W",
          "WSW",
          "SW",
          "SW",
          "SW",
          "S",
          "S",
          "S",
          "SSW",
          "WSW",
          "WSW",
          "SW",
          "SW",
          "SW",
          "SW",
          "WSW",
          "WSW",
          "SW",
          "SW",
          "SW",
          "W",
          "W",
          "W",
          "WNW",
          "NNE",
          "NNW",
          "W",
          "NW",
          "W",
          "WNW",
          null,
          "ENE",
          "ESE",
          "ESE",
          "ESE",
          "ESE",
          "ESE",
          "ESE",
          "SE",
          "E",
          "ESE",
          "E",
          "WNW",
          "WNW",
          "NW",
          "WSW",
          "WNW",
          "W",
          "W",
          "W",
          "WNW",
          "WNW",
          "W",
          "WSW",
          "WSW",
          "E",
          "ESE",
          "ESE",
          "E",
          "ENE",
          "ESE",
          "ENE",
          "SSE",
          "SE",
          "E",
          "ENE",
          "NE",
          "W",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "WSW",
          "WSW",
          "WNW",
          "W",
          "WNW",
          "SW",
          "NE",
          "NE",
          "E",
          "E",
          "E",
          "E",
          "ESE",
          "E",
          "SE",
          "S",
          "SSW",
          "SW",
          "W",
          "WSW",
          "SW",
          "NW",
          "WNW",
          "NW",
          "WNW",
          "W",
          "NW",
          "NW",
          "NW",
          null,
          "ESE",
          "ESE",
          "SE",
          "SSW",
          "SW",
          "WSW",
          "SW",
          "SW",
          "SW",
          "SW",
          "SW",
          "SW",
          "SW",
          "WSW",
          "SW",
          "WSW",
          "SW",
          "SSW",
          "WSW",
          "SW",
          "WSW",
          "WSW",
          "WSW",
          "W",
          "SW",
          "WSW",
          "WSW",
          "S",
          "SW",
          "SSW",
          "SW",
          "SSW",
          "SSW",
          "WSW",
          "WSW",
          "WSW",
          "W",
          "W",
          "WSW",
          "W",
          "W",
          "SW",
          "WSW",
          "W",
          "WNW",
          "W",
          "NW",
          "SW",
          "SSW",
          "ESE",
          "E",
          "E",
          "E",
          "E",
          "ESE",
          "ENE",
          "ESE",
          "ESE",
          "ESE",
          "WNW",
          "WNW",
          "WSW",
          "W",
          "WNW",
          "W",
          "W",
          "WNW",
          "WSW",
          "W",
          "W",
          "W",
          "WSW",
          "ENE",
          "E",
          "E",
          "NE",
          "ESE",
          "ESE",
          "ENE",
          "SE",
          "ESE",
          "ESE",
          "ESE",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WSW",
          "NNE",
          "E",
          "ENE",
          "ENE",
          "E",
          "ESE",
          "NNE",
          "ENE",
          "ESE",
          "ESE",
          "E",
          "SE",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "WNW",
          "W",
          "WNW",
          "WSW",
          "SSW",
          "NW",
          "NE",
          "NW",
          "NNW",
          "E",
          "ENE",
          "ENE",
          "E",
          "SE",
          "SE",
          "WSW",
          "WSW",
          "SSW",
          "NNW",
          "NW",
          "WNW",
          "SW",
          "E",
          "NNW",
          "NNW",
          "WSW",
          "NNE",
          "WNW",
          "W",
          "NW",
          "W",
          "SW",
          "ENE",
          "E",
          "E",
          "E",
          "ESE",
          "ESE",
          "E",
          "WSW",
          "W"
        ],
        "dew_point_temperature_set_1d": [
          39.52,
          38.03,
          39.38,
          36.95,
          37.44,
          38.3,
          38.48,
          38.62,
          38.79,
          38.71,
          38.3,
          38.48,
          37.92,
          37.06,
          39.47,
          40.21,
          41.04,
          42.69,
          43.52,
          44.08,
          44.33,
          43.52,
          47.93,
          44.92,
          42.85,
          42.69,
          41.59,
          41.23,
          40.41,
          39.97,
          40.62,
          41.47,
          42.85,
          43.16,
          42.01,
          42.31,
          42.76,
          42.06,
          44.19,
          42.91,
          44.08,
          45.75,
          44.51,
          40.33,
          42.08,
          41.27,
          41.13,
          40.33,
          40.33,
          38.84,
          38.05,
          39.38,
          38.55,
          39.94,
          40.41,
          40.41,
          40.32,
          40.32,
          41.16,
          42.17,
          40.44,
          40.55,
          42.85,
          42.42,
          40.39,
          42.04,
          42.12,
          41.13,
          40.05,
          36.63,
          36.63,
          38.82,
          38.82,
          39.52,
          38.75,
          37.22,
          37.22,
          35.24,
          36.23,
          35.26,
          36.23,
          36.07,
          35.26,
          35.26,
          34.32,
          34.45,
          36.61,
          34.79,
          32.86,
          34.47,
          33.28,
          32.72,
          32.72,
          31.95,
          32.72,
          32.72,
          31.95,
          31.93,
          28.89,
          30.54,
          29.71,
          28.9,
          29.07,
          26.44,
          25.07,
          26.98,
          25.34,
          23.97,
          24.85,
          26.19,
          27.5,
          28.02,
          29.59,
          30.36,
          31.15,
          32.72,
          31.17,
          29.39,
          27.34,
          26.58,
          25.83,
          25.56,
          23.79,
          25.05,
          25.07,
          23.31,
          22.5,
          23.45,
          24.26,
          24.26,
          23.45,
          22.5,
          29.88,
          30.78,
          30.7,
          27.3,
          24.8,
          27.1,
          28.85,
          29.62,
          29.39,
          31.17,
          33.49,
          38.71,
          37.11,
          37.22,
          37.72,
          37.06,
          39.13,
          39.56,
          39.97,
          41.16,
          40.62,
          40.51,
          40.44,
          39.65,
          38.77,
          43.11,
          43.16,
          44.85,
          46.83,
          46.24,
          47.08,
          47.08,
          45.32,
          44.58,
          52.56,
          43.34,
          44.85,
          44.55,
          46.18,
          47.98,
          48.7,
          45.81,
          43.9,
          42.78,
          46.02,
          43.16,
          43.99,
          43.16,
          43.16,
          42.91,
          46.62,
          45.97,
          45.39,
          45.86,
          46.71,
          46.26,
          42.89,
          36.45,
          38.03,
          37.24,
          35.65,
          35.51,
          35.6,
          34.65,
          34.11,
          35.78,
          36.61,
          34.43,
          34.43,
          33.82,
          34.65,
          34.65,
          34.43,
          33.6,
          33.96,
          33.98,
          34.7,
          33.67,
          34.47,
          34.47,
          36.05,
          36.05,
          34.86,
          34.07,
          31.15,
          30.13,
          30.47,
          31.48,
          32.36,
          33.82,
          33.6,
          32.77,
          33.28,
          32.45,
          32.88,
          32.04,
          30.29,
          29.95,
          29.71,
          27.46,
          19.44,
          11.82,
          13.28,
          5.22,
          5.92,
          1.29,
          6.62,
          11.44,
          14.02,
          15.24,
          19.44,
          23.79,
          22.21,
          22.21,
          22.5,
          21.83,
          22.55,
          23.14,
          19.54,
          21.2,
          21.49,
          20.64,
          24.51,
          25.88,
          24.1,
          24.89,
          25.38,
          23.43,
          23.43,
          24.21,
          23.43,
          23.43,
          23.43,
          24.58,
          22.64,
          22.55,
          23.45,
          22.33,
          21.51,
          20.8,
          20.8,
          20.37,
          20.37,
          21.49,
          21.09,
          21.09,
          23.68,
          23,
          23,
          23.79,
          26.49,
          26.96,
          25.66,
          27.75,
          25.75,
          25.75,
          27.23,
          28.08,
          28.27,
          27.27,
          29.05,
          25.34,
          25.34,
          25.79,
          26.19,
          28.06,
          30.02,
          29.25,
          31.75,
          32.54,
          35.06,
          34.11,
          34.43,
          43.63,
          44.28,
          43.7,
          40.77,
          39.38,
          37.22,
          37.22,
          37.22,
          38.26,
          39.13,
          40.01,
          43.12,
          48.11,
          47.41,
          47.84,
          48.22,
          50.34,
          50.34,
          48.49,
          47.84,
          47.48,
          48.33,
          49.12,
          41.7,
          43.16,
          39.09,
          35.6,
          37.94,
          31.28,
          29.34,
          29.68,
          29.88,
          30.7,
          31.37,
          31.95,
          31.62,
          32.72,
          37.92,
          38.77,
          40.12,
          41.34,
          39.94,
          40.23,
          38.64,
          36.37,
          37.98,
          35.31,
          36.18,
          35.31,
          33.33,
          35.19,
          34.03,
          34.03,
          33.19,
          35.19,
          35.26,
          34.45,
          32.72,
          24.28,
          27.43,
          28.36,
          26.64,
          25.75,
          27.84,
          28.54,
          27.88,
          26.44,
          24.3,
          24.3,
          26.47,
          27.03,
          27.88,
          29.34,
          28.2,
          29.89,
          29.12,
          28.63,
          28.63,
          28.08,
          28.63,
          27.46,
          25.68,
          24.28,
          25.72,
          23.99,
          24.03,
          24.87,
          24.82,
          23.94,
          23.85,
          24.82,
          23.41,
          23.85,
          27.34,
          26.19,
          26.64,
          28.29,
          27.27,
          25.88,
          26.69,
          26.69,
          25.86,
          25.86,
          25.05,
          24.98,
          22.03,
          22.59,
          20.05,
          18.97,
          20.05,
          22.78,
          23.99,
          22.3,
          21.4,
          22.19,
          22.86,
          23.13,
          24.48,
          23.36,
          23.36,
          26.44,
          28.9,
          31.95,
          30.54,
          31.37,
          27.5,
          25.7,
          22.21,
          21.7,
          34.88,
          31.62,
          32.68,
          33.3,
          34.77,
          33.49,
          34.16,
          35.78,
          35.78,
          36.63,
          36.86,
          50.29,
          50.85,
          47.19,
          43.79,
          43.11,
          43.18,
          42.3,
          44.92,
          41.41,
          42.3,
          40.51,
          36.95,
          34.03,
          34.32,
          37,
          36.19,
          35.33,
          39.76,
          42.75,
          44.06,
          44.56,
          44.13,
          42.73,
          43.66,
          41.76,
          40.44,
          43.34,
          42.91,
          42.3,
          43.12,
          41.9,
          40.62,
          40.32,
          39.56,
          38.73
        ],
        "heat_index_set_1d": [
          91.49,
          87.76,
          84.38,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          79.97,
          85.26,
          86.16,
          88.05,
          89.02,
          88.16,
          90.05,
          89.02,
          88.59,
          89.17,
          89.91,
          88.05,
          85.33,
          83.66,
          82.83,
          81.32,
          80.01,
          80.71,
          82.96,
          82.22,
          82.15,
          81.45,
          80.13,
          78.93,
          85.5,
          85.41,
          88.16,
          90.21,
          91.98,
          92.48,
          92.66,
          91.63,
          93.51,
          92.48,
          92.48,
          88.7,
          86.02,
          84.38,
          83.55,
          83.61,
          82.83,
          82.83,
          80.65,
          80.65,
          81.37,
          null,
          null,
          null,
          82.96,
          86.23,
          87.01,
          88.92,
          90.77,
          93.51,
          94.32,
          94.51,
          94.51,
          94.91,
          94.91,
          91.49,
          85.21,
          85.15,
          85.15,
          80.47,
          79.74,
          null,
          79.74,
          81.19,
          null,
          null,
          null,
          null,
          78.69,
          84.27,
          85.91,
          87.66,
          88.27,
          89.76,
          92.23,
          91.29,
          92.23,
          92.23,
          91.29,
          88.88,
          81.86,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          84.67,
          86.31,
          87.15,
          88,
          89.76,
          90.36,
          90.97,
          91.58,
          90.68,
          89.78,
          86.68,
          79.36,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          78.46,
          null,
          79.56,
          86.09,
          85.89,
          88.32,
          87.75,
          88.59,
          90.97,
          90.36,
          90.68,
          90.52,
          88.63,
          86.86,
          82.74,
          79.86,
          80.6,
          82.04,
          81.32,
          81.37,
          80.01,
          null,
          null,
          null,
          null,
          null,
          null,
          83.88,
          84.88,
          86.56,
          87.57,
          90.39,
          93.06,
          87.3,
          81.72,
          84.6,
          83.88,
          84.69,
          83.19,
          null,
          null,
          79.11,
          null,
          null,
          null,
          null,
          79.02,
          null,
          null,
          null,
          null,
          83.98,
          85.6,
          87.42,
          88.43,
          89.33,
          93.7,
          91.96,
          93.92,
          92.93,
          91,
          86.81,
          85.12,
          81.18,
          78.6,
          79.83,
          78.69,
          79.79,
          79.79,
          80.44,
          81.18,
          81.18,
          79.79,
          79.65,
          81.9,
          83.44,
          85.95,
          86.77,
          87.66,
          87.66,
          89.47,
          89.47,
          90.07,
          89.17,
          88,
          84.87,
          83.43,
          82.63,
          81.88,
          80.44,
          79.65,
          78.55,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          79.47,
          80.53,
          81.61,
          83.1,
          83.59,
          84.33,
          85.64,
          85.05,
          85.19,
          83.84,
          81.73,
          80.53,
          79.36,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          78.31,
          80.22,
          82.15,
          82.15,
          82.92,
          82.15,
          82.15,
          82.15,
          79.5,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          78.26,
          78.26,
          79.36,
          79.63,
          81.7,
          82.31,
          82.47,
          84.49,
          84.49,
          83.86,
          81.1,
          79.66,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          78.6,
          79.79,
          80.83,
          82.29,
          83.8,
          84.45,
          84.38,
          85.15,
          86.86,
          85.15,
          81.99,
          80.6,
          78.84,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          79.3,
          80.08,
          82.22,
          82.78,
          83.48,
          84.34,
          84.24,
          84.06,
          82.63,
          81.1,
          79.56,
          78.49,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          78.49,
          null,
          79.41,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          79.97,
          80.01,
          80.65,
          82.04,
          81.28
        ]
      },
      "QC_FLAGGED": false,
      "RESTRICTED": false
    }
  ],
  "SUMMARY": {
    "NUMBER_OF_OBJECTS": 1,
    "RESPONSE_CODE": 1,
    "RESPONSE_MESSAGE": "OK",
    "METADATA_PARSE_TIME": "0.2 ms",
    "METADATA_DB_QUERY_TIME": "8.8 ms",
    "DATA_QUERY_TIME": "7.4 ms",
    "QC_QUERY_TIME": "19.4 ms",
    "PRECIP_QUERY_TIME": "11.0 ms",
    "DATA_PARSING_TIME": "12.0 ms",
    "TOTAL_DATA_TIME": "49.8 ms",
    "VERSION": "v2.24.6"
  },
  "QC_SUMMARY": {
    "QC_CHECKS_APPLIED": [
      "sl_range_check"
    ],
    "TOTAL_OBSERVATIONS_FLAGGED": 0,
    "PERCENT_OF_TOTAL_OBSERVATIONS_FLAGGED": 0
  },
  "UNITS": {
    "position": "ft",
    "elevation": "ft",
    "air_temp": "Fahrenheit",
    "relative_humidity": "%",
    "wind_speed": "knots",
    "wind_direction": "Degrees",
    "peak_wind_speed": "knots",
    "wind_gust": "knots",
    "peak_wind_direction": "Degrees",
    "solar_radiation": "W/m**2",
    "volt": "volts",
    "precip_accumulated": "Inches",
    "precip_intervals": "Inches",
    "dew_point_temperature": "Fahrenheit",
    "heat_index": "Fahrenheit"
  }
}